@import "tailwindcss";

@config "../../tailwind.config.js";

@utility df-bg-overlay {
  @apply opacity-0;
}

@utility df-intro {
  @apply opacity-100;
}
@utility df-expando {
  @apply max-h-full;
}
@utility df-info {
  @apply static block;
}
@utility df-info__wrapper {
  @apply mx-auto;
}
@utility df-info__article {
  @apply mb-8;
}
